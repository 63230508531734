// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".bg-mid{background-color:#f2f2f2!important}.mixin-base-bordered-card{border-radius:4px;box-shadow:0 4px 10px rgba(0,0,0,.04)}.mixin-gradient-overlay{width:100%;height:3rem;background:linear-gradient(0deg,#fff,transparent)}.mixin-gradient-overlay,.mixin-gradient-overlay-right{content:\"\";position:absolute;pointer-events:none;bottom:0}.mixin-gradient-overlay-right{width:3rem;height:100%;background:linear-gradient(270deg,#fff,transparent)}.text-ellipsis{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;max-width:calc(100% - 8px)}.profile-picture{border-radius:50%;width:32px;height:32px;background-color:#dcb1e3;color:#5f1903;display:flex;align-items:center;justify-content:center;font-weight:700;font-size:18px}.profile-picture.md{width:24px;height:24px;font-size:14px}.profile-picture.sm{width:18px;height:18px;font-size:12px}", ""]);
// Exports
exports.locals = {
	"zDrawer": "950",
	"zPopup": "1060",
	"zGraphAnnotationPrompt": "99"
};
module.exports = exports;
